// TagsSuggestion.scss

.rti--container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px;
    border-radius: 4px;
    position: relative;
    width: 100%;

    &:focus-within {
      border-color: #007bff;
    }
  }
  
  .rti--tag {
    display: flex;
    align-items: center;
    background-color: #e0e0e0;
    border-radius: 3px;
    padding: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
  
    button {
      background: none;
      border: none;
      cursor: pointer;
      margin-left: 5px;
    }
  }
  
  .rti--input {
    flex: 1;
    min-width: 100px; // Optional: ensures input doesn't get too small
    border: none;
    outline: none;
    padding: 5px;
    font-size: 14px;
  
    &::placeholder {
      color: #999;
    }
  }

  .suggestion-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Box shadow for dropdown
  
    .suggestion-dropdown-item {
      cursor: pointer;
      padding: 5px 10px;
      border-bottom: 1px solid #eee;
      font-size: 13px;
      background: #e9e4e496;
  
      &:hover {
        background: #d6d4d4; // Change background color on hover
      }
  
      &:last-child {
        border-bottom: none; // Remove border from the last item
      }
    }
  }